<template>
    <div class="food-list">
        <el-container>
            <el-main>
                <slideshow :slideshowList="slideshowList"></slideshow>
                <img class="debris" src="../../../static/scenery/debris.png" />
                <div class="content" style="width:65%;margin:0 auto">
                    <!-- <div class="bg-image1"></div>
                    <div class="bg-image2"></div>
                    <div class="bg-image3"></div>
                    <div class="bg-image4"></div>
                    <div class="bg-image5"></div> -->
                    <div class="content-title">
                        <img class="home-icon" src="../../../static/scenery/home.png" alt="" style="width:20;height:20;">
                        <span style="margin-right:6px;font-weight:bold" @click="Jump('/',0)">首页</span>
                        >
                        <span style="margin-right:6px" @click="Jump('/essential')">条子泥攻略</span>
                        >
                        <span style="margin-left:6px;color:#509AB1">舌尖美味</span>
                    </div>
                    <p class="image">舌尖美味</p>
                   <el-tabs v-model="activeName" @tab-click="handleClick" stretch style="width: 100%;padding: 0 30px;" >
                        <el-tab-pane 
                            v-for="item in restaurantList" :key="item.name" :label="item.label" :name="item.name"
                        > 
                            <div class="picture-show">
                          
                                <div class="restaurant-detail" v-for="(item,index) in foodList" :key="index">
                                    <div class="corner"
                                        :style="{
                                        'background-image': 'url(' + item.picUrl + ')',}"
                                    >
                                        <!-- <span class="corner-box"></span> -->
                                        <img src="~static/tzngl/box-border.png" style="opacity:0.8" alt="">
                                    </div>
                                    <div class="tel-address">
                                        <p class="name">{{item.restName}}<el-button v-if="item.toUrl" @click.native="goProject(item.toUrl)" style="background-color: #254f63;" type="primary">立即预定</el-button></p>
                                        <p class="tel" v-if="item.telphone !== '' ">
                                            <img src="../../../static/tzngl/dianhua.png" alt="">
                                            电话：{{item.telphone}}</p>
                                        <p class="adress">
                                            <img src="../../../static/tzngl/mp-location.png" alt="">
                                            地址：{{item.restAddress}}</p>
                                        <div class="recommend">
                                            <p class="delicacy">美食推荐：</p>
                                            <span class="specialty">{{item.recomFood}}</span>
                                        </div>
                                        <!-- <div style="display: flex;justify-content: center;">
                                            <el-button style="background-color: #254f63;" type="primary">立即预定</el-button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <el-pagination
                                background
                                prev-text="上一页"
                                next-text="下一页"
                                @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                layout="total,prev, pager, next"
                                :page-size="info.size"
                                :total="totalNum">
                            </el-pagination>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <leftFloat :floatList="floatList" />
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { slideshow } from '@/api/test/test'
import { tzn_diningAPI } from '@/api/test/scenery'
export default {
    name:'FoodList',
    components:{},
    data(){
        return{
            slideshowList:[],
            activeName:'0',
            restaurantList:[
                { name:"0",label:"景区美食"},
                { name:"1",label:"周边美食"}
				// { name:"0",label:""}
            ],
            floatList: {
                list: [
                { name: "基本信息", url: "/essential" },
                { name: "舌尖美味", url: "flush" },
                { name: "酒店住宿", url: "/scenicSpot" },
                { name: "景区互动", url: "/interaction" },
                { name: "行程推荐", url: "/travel" },
                ],
                title: "条子泥攻略",
            },
            foodList:[],
            // 分页
            currentPage: 1,
            totalNum:null,
            info:{
                page:0,
                size:12,
                restType:0
            }
        }
    },
    created() {
    },
    mounted() {
        //头部轮播图
        slideshow().then(res => {
            this.slideshowList = res
        })

        this.tzn_diningData()
    },
    methods:{
        goProject(src) {
            window.open(src, "_blank");
        },
        //获取数据列表
        tzn_diningData(){
            tzn_diningAPI(this.info).then(res => {
               this.totalNum = res.count
               this.foodList = res.data
            })
        },
        //分页
        handleCurrentChange(val) {
        this.info.page=val-1    
        this.tzn_diningData()
        },
        handleSizeChange(val) {
        this.info.size=val
        this.tzn_diningData()
        },
        //teb切换
        handleClick(e){
            if(e.label==='景区美食') {
            this.info.activeName='0'
            this.info.restType='0'
            }
            else {
            this.info.activeName='1'
            this.info.restType='1'
            }
            this.tzn_diningData()
            // console.log(e.label)
        },
        //左边列表跳换
        handleJump(url){
            this.$router.push(url)
        },
        //数据格式化
        decodeHTML(str) {
            var s = "";
            if (!str || str.length == 0) return "";
                s = str.replace(/&amp;/g, "&");
                s = s.replace(/&lt;/g, "<");
                s = s.replace(/&gt;/g, ">");
                s = s.replace(/&nbsp;/g, " ");
                s = s.replace(/&#39;/g, "\'");
                s = s.replace(/&quot;/g, "\"");
                return s;
        }
    }
}
</script>


<style scoped>

.food-list{
    background: url('../../../static/scenery/backgd.png') ;
}
.el-main {
    padding: 0;
    overflow-x: hidden;
}
/deep/ .el-carousel__indicators{
    bottom: 5%;
}
.debris{
    width: 100%;
    margin-top: -157px;
    z-index: 8;
    background: transparent;
    position: relative;
}
.content{
    position: relative;
}
.bg-image1{
    width: 390px;
    height: 292px;
    background-image: url('../../../static/scenery/dfbh.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: -32%;
    top: 5%;
}
.bg-image2{
    width: 428px;
    height: 353px;
    background-image: url('../../../static/scenery/hly.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: -33%;
    top: 36%;
}
.bg-image3{
    width: 428px;
    height: 353px;
    background-image: url('../../../static/scenery/hlz.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: -33%;
    top: 30%;
}
.bg-image4{
    width: 428px;
    height: 353px;
    background-image: url('../../../static/scenery/dfbh.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    right: -33%;
    bottom: 26%;
}
.bg-image5{
    width: 428px;
    height: 353px;
    background-image: url('../../../static/scenery/hly.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    left: -33%;
    bottom: 12%;
}
.content-title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 92px;
    background-image: url('../../../static/scenery/title.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: left;
    line-height: 92px;
    font-size: 24px;
    letter-spacing: 5px;
}
.home-icon{
    width: 20px;
    height: 20px;
    margin: 0 16px 0 53px;
}
.content-title>span:hover{
    cursor: pointer;
    color: #509AB1;
}
.image{
    width: 450px;
    height: 250px;
    background-image: url('../../../static/scenery/img-box.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    line-height: 250px;
    margin: 55px auto;
    letter-spacing: 10px;
    font-size: 42px;
    color: #509AB1;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.28);
}
.el-tabs{
    /* display: flex;
    justify-content: space-between; */
}
/deep/ .el-tabs__item{
    background-image: url('../../../static/scenery/bgbox.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #F6D397;
    margin: 0 15px;
    padding: 0;
    height: 60px;
    line-height: 60px;
    font-size: 28px;
}
/deep/ .el-tabs__item.is-active{
    background-image: url('../../../static/scenery/bgbox-select.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    color: #509AB1;
}
/deep/ .el-tabs__active-bar{
    display: none;
}
/deep/ .el-tabs__nav-wrap::after{
    display: none;
}
/deep/ .el-tabs__nav.is-stretch{
    min-width: inherit;
    width: 100%;
    box-sizing: border-box;
    padding: 0 180px;
    margin-bottom: 112px;
}
.picture-show{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /* justify-content: flex-start; */
    align-items:center;
}
.restaurant-detail{
    margin: 13px;
    width: 30%;
    height: 600px;
}
.corner{
    position: relative;
    background-repeat: no-repeat;
    background-size: 98% 98%;
    /* width: 378px;
    height: 379.1px;
    position: relative; */
}
/* .corner-box{
    width: 100%;
    height: 100%;
    background-image: url('../../../static/tzngl/box-border.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 0;
    left: -2px;
} */
.corner>img{
    display:block;
    width: 100%;
    height: 100%;
    position: relative;
    left: -3px;
}
.tel-address{
    /* width: 378px; */
    /* height: 185px; */
    width: 100%;
    /* background-image: url('../../../static/scenery/author.png'); */
    /* background-color: #fff1cc; */
    font-size: 9px !important;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 20px;
}
.name{
    font-size: 24px;
    font-weight: bold;
    color: #509AB1;
    line-height: 27px;
    margin-bottom: 6px;
    display: flex;
    justify-content: space-between;
}
.tel,.adress{
    font-size: 16px;
    color: #509AB1;
    line-height: 27px;
    overflow: hidden;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
}
.recommend{   
    display: flex;
    align-items: center; 
    font-size: 16px;
    color: #509AB1;
    line-height: 27px;
    margin-left: 6px;
}
.delicacy{
    width:80px;
    background-color:transparent;
    border:none;
    color:#509AB1;
}
.specialty{
    flex: 1;
    margin: 0 3px;
    background-color: #254f63;
    color: #f5e0af;
    overflow: hidden;
    text-indent: 8px;
}
.el-pagination{
    display: flex;
    justify-content: center;
    margin: 100px 0 50px;
}
/deep/ .el-pagination button, .el-pagination span{
    font-size: 16px;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #509AB1;
}
/deep/ .el-pagination button:hover{
    color: #509AB1;
}
/deep/ .el-pagination.is-background .el-pager li:not(.disabled):hover{
    color: #509AB1;
}
</style>